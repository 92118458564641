import React from "react";
import { useStaticQuery, graphql, Link } from "gatsby";
import { StaticImage } from "gatsby-plugin-image";

// Components
import SecondaryLanding from "../../../layouts/secondary-landing";
import HeroChevron from "../../../components/hero/hero-chevron";
import Breadcrumb from "../../../components/breadcrumb/breadcrumb";
import SEO from "../../../components/seo/seo";
import StretchedLinkCards from "../../../components/card/stretched-link-cards";
import Button from "../../../components/custom-widgets/button";
import getHeroImgVariables from "../../../helpers/getHeroImgVariables";
import MobileAppDownloadBtn from "../../../components/mobile-online-banking/mobile-app-download-btn";
import useCardBlogsData from "../../../hooks/use-card-blogs-data";
import getFeaturedCardBlogData from "../../../helpers/getFeaturedCardBlogData";
import BestBanksDefault from "../../../components/best-banks/best-banks-default";
import ContactInfo from "../../../components/contact-info/contact-info";

import WaFdMobileApp from "../../../images/wafd-mobile-app.gif";

const FreeBusinessCheckingAccount = () => {
  const imgData = useStaticQuery(graphql`
    query {
      imgVariableXXL: file(
        relativePath: {
          eq: "hero/business-banking/business-checking-account/simple-business-checking/hero-business-simple-checking-02242023-XXL.jpg"
        }
      ) {
        ...heroChevronImageFragmentXXL
      }
      imgVariableXL: file(
        relativePath: {
          eq: "hero/business-banking/business-checking-account/simple-business-checking/hero-business-simple-checking-02242023-XL.jpg"
        }
      ) {
        ...heroChevronImageFragmentXL
      }
      imgVariableLG: file(
        relativePath: {
          eq: "hero/business-banking/business-checking-account/simple-business-checking/hero-business-simple-checking-02242023-LG.jpg"
        }
      ) {
        ...heroChevronImageFragmentLG
      }
      imgVariableMD: file(
        relativePath: {
          eq: "hero/business-banking/business-checking-account/simple-business-checking/hero-business-simple-checking-02242023-MD.jpg"
        }
      ) {
        ...heroChevronImageFragmentMD
      }
      imgVariableSM: file(
        relativePath: {
          eq: "hero/business-banking/business-checking-account/simple-business-checking/hero-business-simple-checking-02242023-SM.jpg"
        }
      ) {
        ...heroChevronImageFragmentSM
      }
      imgVariableXS: file(
        relativePath: {
          eq: "hero/business-banking/business-checking-account/simple-business-checking/hero-business-simple-checking-02242023-XS.jpg"
        }
      ) {
        ...heroChevronImageFragmentXS
      }
      imgVariableXXS: file(
        relativePath: {
          eq: "hero/business-banking/business-checking-account/simple-business-checking/hero-business-simple-checking-02242023-XXS.jpg"
        }
      ) {
        ...heroChevronImageFragmentXXS
      }
      articleImg1: file(relativePath: { eq: "cards/articles-cards/business-owner-opens-account-online-card.jpg" }) {
        childImageSharp {
          gatsbyImageData(
            placeholder: BLURRED
            quality: 100
            width: 600
            transformOptions: { cropFocus: CENTER }
            layout: CONSTRAINED
          )
        }
      }
      articleImg2: file(relativePath: { eq: "cards/articles-cards/credit-card-transaction.jpg" }) {
        childImageSharp {
          gatsbyImageData(
            placeholder: BLURRED
            quality: 100
            width: 600
            transformOptions: { cropFocus: CENTER }
            layout: CONSTRAINED
          )
        }
      }
      articleImg3: file(relativePath: { eq: "cards/articles-cards/best-bank-small-business-card.jpg" }) {
        childImageSharp {
          gatsbyImageData(
            placeholder: BLURRED
            quality: 100
            width: 600
            transformOptions: { cropFocus: CENTER }
            layout: CONSTRAINED
          )
        }
      }
      wafdOnlineBankingImage: file(relativePath: { eq: "wafd-bank-mobile-app.png" }) {
        childImageSharp {
          gatsbyImageData(placeholder: BLURRED, quality: 100)
        }
      }
      debitCard: file(relativePath: { eq: "credit-cards/thumbnail-debit-consumer-horizontal.jpg" }) {
        childImageSharp {
          gatsbyImageData(placeholder: BLURRED, quality: 100)
        }
      }
    }
  `);

  const heroChevronData = {
    id: "small-business-hero",
    ...getHeroImgVariables(imgData),
    altText: "Craftswoman with tablet in her art studio",
    heroBody: {
      components: [
        {
          id: 1,
          heading: {
            class: "text-white",
            text: "*Free Business Checking for Your Small Business"
          }
        },
        {
          id: 2,
          button: {
            id: "small-business-hero-cta",
            text: "Open an Account",
            url: "https://wafd.my.site.com/nPortal__SelfRegistration?core=es5&product=a0uUH000000dyHO",
            class: "btn-white",
            externalLink: true,
            target: "blank",
            containerClass: "mb-3"
          }
        },
        {
          id: 3,
          tertiaryText: {
            class: "text-white hero-tertiary-text",
            text: "*Nonsufficient funds fee may apply."
          }
        }
      ]
    }
  };

  const breadcrumbData = [
    {
      id: 1,
      url: "/business-banking",
      title: "Business Banking"
    },
    {
      id: 2,
      url: "/business-banking/business-checking-account",
      title: "Business Checking"
    },
    {
      id: 3,
      active: true,
      title: "Free Business Checking"
    }
  ];

  const title = "Free Small Business Checking",
    description =
      "Learn about WaFd Bank's Free Small Business Checking account. Our free business checking account is designed for LLC's, sole proprietors and entrepreneurs.";

  const SEOData = {
    title: title,
    meta: [
      {
        name: "title",
        property: "og:title",
        content: title
      },
      {
        name: "description",
        property: "og:description",
        content: description
      },
      {
        property: "og:url",
        content: "https://www.wafdbank.com/business-banking/business-checking-account/free-business-checking"
      },
      {
        property: "og:type",
        content: "website"
      },
      {
        property: "og:site_name",
        content: "wafdbank"
      },
      {
        name: "application-name",
        content: "WaFd Bank public website"
      },
      {
        property: "og:image",
        content: "https://www.wafdbank.com/images/adsearch/og-business-simple-checking-02242023.jpg"
      }
    ]
  };

  const openAccountBtnData = {
    text: "Open an Account",
    containerClass: "mb-3",
    showIcon: false,
    url: "https://wafd.my.site.com/nPortal__SelfRegistration?core=es5&product=a0uUH000000dyHO",
    externalLink: true,
    target: "blank",
    class: "btn-primary w-100 w-sm-auto"
  };

  const iconListData = [
    {
      img: "../../../images/icons/credit-card-processing-icon.svg",
      text: "Credit Card Processing"
    },
    {
      img: "../../../images/icons/payroll-icon.svg",
      text: "Payroll Services"
    },
    {
      img: "../../../images/icons/atms-icon.svg",
      text: "Free Access to over 40,000 ATMs"
    },
    {
      img: "../../../images/icons/commercial-real-estate-financing-icon.svg",
      text: "Treasury Management Services"
    },
    {
      img: "../../../images/icons/debit-card-icon.svg",
      text: "Visa Debit Card"
    }
  ];

  const onlineBankingIconListData = [
    {
      img: "../../../images/icons/pay-bills-icon.svg",
      text: "Pay bills online"
    },
    {
      img: "../../../images/icons/mobile-deposit-icon.svg",
      text: "Mobile Photo Deposit & Remote Deposit Capture"
    },
    {
      img: "../../../images/icons/eStatements-icon.svg",
      text: "eStatements"
    },
    {
      img: "../../../images/icons/eWire-icon.svg",
      text: "eWire Transfer Service"
    },
    {
      img: "../../../images/icons/sweep-accounts-icon.svg",
      text: "Automatic Sweep Accounts"
    },
    {
      img: "../../../images/icons/ach-payments-icon.svg",
      text: "Electronic ACH Payments"
    }
  ];

  const cardsData = useCardBlogsData([
    "/blog/small-business/what-is-needed-to-open-business-bank-account",
    "/blog/small-business/ensure-credit-card-pci-compliance",
    "/blog/small-business/what-is-the-best-bank-for-a-small-business"
  ]);

  const featuredCardsData = getFeaturedCardBlogData(cardsData, { hasText: false });

  const articleCardsData = {
    sectionClass: "bg-white",
    hasGrowEffect: true,
    cards: featuredCardsData
  };

  return (
    <SecondaryLanding footerBorder={false}>
      <SEO {...SEOData} />
      <HeroChevron {...heroChevronData} />
      <Breadcrumb data={breadcrumbData} />
      <section id="simple-small-business-checking-section" className="container">
        <h1>Free Business Checking</h1>
        <h2 className="text-success">Only $100 initial deposit to open.</h2>
        <h3>
          As straight forward as they come, Free Business Checking is perfect for managing a small business, LLC or Sole
          Proprietorship. Designed for businesses with lower monthly transactions and maintaining lower balances, Free
          Business Checking accounts allow you to deposit and write checks, process payroll and more.
        </h3>
        <Button id="open-account-cta-1" {...openAccountBtnData} />
        <p className="text-sm text-muted mb-0" id="initial-deposit-disclaimer">
          *Nonsufficient funds fee may apply.
        </p>
      </section>
      <section className="container py-0">
        <h2>
          Discover why WaFd Bank is the{" "}
          <Link
            to="/blog/small-business/what-is-the-best-bank-for-a-small-business"
            id="internal-link-best-bank-for-a-small-business"
          >
            Best Bank for Small Business
          </Link>
        </h2>
        <div className="row">
          <div className="col-md-6 mb-3 mb-md-0">
            <StaticImage
              src="../../../images/thumbnail-business-debit-card-730.jpg"
              alt="WaFd Bank contactless debit card"
              placeholder="blurred"
              quality="100"
            />
          </div>
          <div className="col-md-6">
            {iconListData.map((data, index) => (
              <div className="row mb-3" key={index} id={`checking-list-item-${index + 1}`}>
                <div className="col-2 col-sm-1 col-md-2 col-lg-1">
                  <img src={data.img} alt="" />
                </div>
                <div className="col-10 col-lg-11 font-weight-semi-bold">{data.text}</div>
              </div>
            ))}
          </div>
        </div>
      </section>

      <section className="container pb-0">
        <h2>
          Convenient and Secure{" "}
          <Link to="/business-banking/online-banking" id="internal-link-business-online-banking">
            Online Banking
          </Link>
        </h2>
        <div className="row">
          <div className="col-md-6 mb-3 mb-md-0">
            <p>
              From online and mobile banking to remote deposits, we offer a wealth of Treasury Management services for
              your convenience.
            </p>
            {onlineBankingIconListData.map((data, index) => (
              <div className="row mb-3" key={index} id={`online-banking-list-item--${index + 1}`}>
                <div className="col-2 col-sm-1 col-md-2 col-lg-1">
                  <img src={data.img} alt="" />
                </div>
                <div className="col-10 col-lg-11 font-weight-semi-bold">{data.text}</div>
              </div>
            ))}
          </div>
          <div className="col-md-6">
            <div className="row">
              <div className="col-md-6 text-center">
                <img src={WaFdMobileApp} className="w-100 w-sm-50 w-md-100" alt="WaFd Bank Mobile App home screen." />
              </div>
              <div className="col-md-6">
                <div className="flex-md-column justify-content-center row">
                  <div className="col-auto d-md-block d-none mb-md-3 text-center">
                    <StaticImage
                      src="../../../images/wafd-mobile-app-qr-code.png"
                      alt="WaFd App QR Code."
                      placeholder="blurred"
                      quality="100"
                    />
                    <div className="text-xs text-gray-60 text-center">Scan to Downloads</div>
                  </div>
                  <div className="col-auto mb-md-3 text-center">
                    <MobileAppDownloadBtn
                      idName="apple-store"
                      storeBtn="apple"
                      url="https://apps.apple.com/us/app/wafd-bank/id1054549841"
                    />
                    <div className="text-xs text-gray-60 text-center">For Apple devices</div>
                  </div>
                  <div className="col-auto text-center">
                    <MobileAppDownloadBtn
                      idName="google-play-store"
                      storeBtn="google"
                      url="https://play.google.com/store/apps/details?id=com.washingtonfederal.mobilebanking&amp;hl=en_US"
                    />
                    <div className="text-xs text-gray-60 text-center">For Android devices</div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
      <StretchedLinkCards {...articleCardsData} />
      <ContactInfo type="business" />
      <BestBanksDefault />
    </SecondaryLanding>
  );
};

export default FreeBusinessCheckingAccount;
